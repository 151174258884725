import React, { useState, useRef, useCallback } from 'react';
import { Lrc } from 'react-lrc';
import Snowfall from 'react-snowfall';

import music from './data.js';

import './Year2020.css'

const lrcStyle = {
    flex: 1,
    minHeight: 0,
};

const Year2020 = () => {
    const [currentTime, setCurrentTime] = useState(0);
    const [currentLine, setCurrentLine] = useState(0);
    const onTimeUpdate = useCallback(
        (event) => setCurrentTime(event.target.currentTime),
        [],
    );
    const lrcRef = useRef();
    const audioRef = useRef();

    const lineRenderer = useCallback(({ line, index, active }) => {
        const { content, startMillisecond } = line;
        const distanceFromCurrent = Math.abs(index - currentLine);
        return (
            <div
                onClick={() => audioRef.current.currentTime = startMillisecond / 1000.0}
                style={{
                    textAlign: 'center',
                    margin: '10px 15%',
                    color: 'white',
                    transform: `scale(${active ? 1.3 : 1})`,
                    transition: 'transform 300ms',
                    opacity: active ? 1 : 1 / (distanceFromCurrent + 1) + 0.1
                }}
            >
                {startMillisecond < 244000 ? content : <>
                    <span>Och så önskar jag dig en <br /></span>
                    <span style={{ color: currentTime > 246.10 ? 'red' : 'inherit' }}>Riktigt </span>
                    <span style={{ color: currentTime > 247.10 ? 'red' : 'inherit' }}>God </span>
                    <span style={{ color: currentTime > 248.10 ? 'red' : 'inherit' }}>Jul!</span>
                </>}
            </div>
        );
    }, [currentLine, currentTime]);
    const onCurrentLineChange = useCallback((line) => setCurrentLine(line.index), [setCurrentLine]);

    return (
        <div className="Year2020">
            <Snowfall />
            <div className="Year2020-top">
                <div className="Year2020-actions">
                    <audio
                        ref={audioRef}
                        src={music.src}
                        autoPlay
                        controls
                        onTimeUpdate={onTimeUpdate}
                    />
                </div>
            </div>
            <Lrc
                ref={lrcRef}
                style={lrcStyle}
                lrc={music.lrc}
                currentMillisecond={currentTime * 1000}
                lineRenderer={lineRenderer}
                onLineUpdate={onCurrentLineChange}
            />
        </div>
    );
};

export default Year2020;