const data = {
    src: './source/julrim2021.mp3',
    lrc: `
[00:00.43]Yo yo yo
[00:02.98]Ho ho ho
[00:05.25]Dyker upp i husen
[00:07.73]Tänder alla ljusen
[00:10.31]Ni vet vem det är
[00:12.53]Tomten är här

[00:14.60]Nu är det jul igen
[00:15.95]Det ska bli kul min vän
[00:17.30]Vi glider runt och delar ut klappar till barnen men
[00:19.85]Jag tror jag sa det själv
[00:21.14]Jag tar det om igen
[00:22.88]Du är söt men ej speciell
[00:24.81]Samma regler gäller för dig
[00:26.62]För present ska du vara snäll
[00:28.69]Så se till att skärpa dig
[00:30.69]Jag vill inte ha din önskelista
[00:32.95]Alla saker du vill ha är trista
[00:34.86]Det man förtjänar är det man får
[00:36.99]Du bara grälar, blir nog inget i år
[00:39.20]Din bortskämda unge, tror du kan få allt
[00:41.52]Det ska vara snö, sol och lagom kallt
[00:43.65]Det ska vara sill, lax och Janssons frestelse
[00:46.36]Julen ska vara årets största händelse
[00:48.56]Chilla lite, minska pressen
[00:50.85]Jag pallar inte all den stressen
[00:53.35]Men du bryr dig väl inte om vad jag tycker
[00:55.90]Du tar det du vill ha som familjen Knyckertz
[00:58.19]Fast nu har du ändå stått ut med din släkt
[01:00.63]Och egentligen är väl ingen perfekt
[01:02.90]Så kanske är det inte bara de snällaste barnen
[01:05.30]Som ska hitta paket när de tittar under granen
[01:08.10]Även du, trots ditt beteende
[01:10.26]Förtjänar att på julen gå runt med ett leende
[01:12.76]Så häll upp musten, skåla och svälj
[01:15.38]Ha en God Jul och en Trevlig Helg
`,
  };

export default data;
