const data = {
    src: './source/Julrim2024.m4a',
    lrc: `
[00:00.51]I allt mörker är du mitt ljus
[00:03.38]Alla andra är bara brus
[00:06.27]Jag behöver ingen snö, jag behöver inga paket
[00:09.16]Men jag behöver dig, det är något jag vet

[00:12.06]Jag har känt dig nu i många år
[00:14.44]Men vårt första möte känns som igår
[00:16.80]Jag tänkte egentligen stanna hemma, kände mig rätt lat
[00:19.90]Men jag ville bara få ett slut på allt tjat

[00:22.54]"Häng med och fira jul!"
[00:23.75]"Kom igen, det blir kul!"

[00:25.94]Jag orkade inte tjafsa så sa okej
[00:28.37]Ganska snabbt började jag ångra mig
[00:30.88]Man tvingades prata med hela tjocka släkten
[00:33.60]Min morfar och min faster och hennes man med andedräkten

[00:36.91]När jag gjorde mitt bästa för att undvika min släkt
[00:40.02]Såg jag dig där ute på gatan i en vacker dräkt
[00:43.83]Du vände dig mot huset och jag såg ditt ståtliga skägg
[00:47.17]Jag blev knäsvag, tog stöd mot en vägg

[00:50.53]Det var det vackraste jag hade sett
[00:53.14]Du tittade upp och log brett
[00:55.96]Du gick upp till huset och knackade på
[00:58.46]Jag rörde mig mot dörren men kunde knappt gå

[01:01.74]Dörren öppnades och där stod du med din säck
[01:04.71]Jag visste inte vad jag skulle ta mig till, blev helt väck
[01:08.31]Du räckte fram ett paket och sa att det var till mig för att jag varit snäll
[01:12.93]Jag blev chockad, det här var mitt livs bästa kväll

[01:16.36]Jag började öppna paketet, du försvann innan jag ens var klar
[01:20.55]Men det gjorde ingenting, för i mitt hjärta skulle du alltid finns kvar

[01:24.68]Vi har setts många gånger sedan dess
[01:27.12]Men när vi ses blir jag fortfarande nervös och känner stress
[01:30.15]Jag blir svartsjuk när du träffar andra
[01:32.39]Och det blev inte bättre när jag såg dig kyssa mamma

[01:35.92]Jag har aldrig låtit dig veta hur jag känner
[01:38.72]"Spela bara cool", säger mina vänner

[01:41.90]Så jag står och spelar oberörd när du säger hejdå
[01:45.30]Men så springer jag till fönstret när du går
[01:48.20]What is love ska det vara så svårt
[01:50.52]Santa don't hurt me, no more

[01:53.73]Jag älskar tomten, han är bäst
[01:55.84]Nu gör vi julen till en redig fest
[01:59.04]...
`,
  };

export default data;
