import React, { useState, useRef, useCallback } from 'react';
import { Lrc } from 'react-lrc';
import Snowfall from 'react-snowfall';

import music from './data2024.js';

import './Year2024.css'

const lrcStyle = {
    flex: 1,
    minHeight: 0,
};

const Year2024 = () => {
    const [currentTime, setCurrentTime] = useState(0);
    const [currentLine, setCurrentLine] = useState(0);
    const onTimeUpdate = useCallback(
        (event) => setCurrentTime(event.target.currentTime),
        [],
    );
    const lrcRef = useRef();
    const audioRef = useRef();

    const lineRenderer = useCallback(({ line, index, active }) => {
        const { content, startMillisecond } = line;
        const distanceFromCurrent = Math.abs(index - currentLine);
        return (
            <div
                onClick={() => audioRef.current.currentTime = startMillisecond / 1000.0}
                style={{
                    textAlign: 'center',
                    margin: '10px 15%',
                    color: 'white',
                    transform: `scale(${active ? 1.3 : 1})`,
                    transition: 'transform 300ms',
                    opacity: active ? 1 : 1 / (distanceFromCurrent + 1) + 0.1
                }}
            >
                {startMillisecond < 119000 ? content : <>
                    {currentTime > 119.500 ? "❤️" : <span style={{whiteSpace: "pre"}}> </span>}
                    {currentTime > 120.000 && "🎅"}
                    {currentTime > 120.500 && "❤️"}
                    </>}
            </div>
        );
    }, [currentLine, currentTime]);
    const onCurrentLineChange = useCallback((line) => setCurrentLine(line.index), [setCurrentLine]);

    return (
        <div className="Year2024">
            <Snowfall />
            <div className="Year2024-top">
                <div className="Year2024-actions">
                    <audio
                        ref={audioRef}
                        src={music.src}
                        autoPlay
                        controls
                        onTimeUpdate={onTimeUpdate}
                    />
                </div>
            </div>
            <Lrc
                ref={lrcRef}
                style={lrcStyle}
                lrc={music.lrc}
                currentMillisecond={currentTime * 1000}
                lineRenderer={lineRenderer}
                onLineUpdate={onCurrentLineChange}
            />
        </div>
    );
};

export default Year2024;