const data = {
    src: './source/julrim.m4a',
    lrc: `
[00:00.31]Det finns inget lätt sätt att säga det här
[00:04.95]Men nu är det som det är
[00:07.26]Tyvärr
[00:10.12]Det man vill är inte alltid det man får
[00:13.90]Julen, den är inställd i år
[00:17.62]Inga nära, inga kära, inget tjo och tjim
[00:22.85]Men ingenting i världen kan stoppa mina rim
[00:26.75]För rimmen de är ostoppbara
[00:29.84]Underbara, ingen fara, låt de bara vara
[00:33.35]Så kommer här en liten saga
[00:36.57]En vinternatt för länge sedan
[00:39.64]Sören hade precis lämnat smedjan
[00:42.59]Han pulsade genom den djupa snön
[00:45.17]När han såg någon röra sig nere vid sjön
[00:49.52]Hans chock var på den högsta nivå
[00:51.34]Vad kunde det vara som stod på?
[00:54.86]Här ute brukar ju ingen annan gå
[00:57.82]Han ställde sig på tå
[00:58.91]Och ropade "Hallå?"
[01:01.62]Ett avlägset "Hjälp!" hörde han då
[01:05.00]Han gick ner mot sjön i högsta fart
[01:07.90]Behöver nån hjälp ska de få det såklart
[01:10.57]Vid sjön såg han en man klädd helt i rött
[01:13.56]Mannens stora skägg var helt genomblött
[01:17.87]"Hur står det till?" frågade Sören högt
[01:20.70]Mannen vände sig om och sade med lugn röst
[01:24.64]Det finns inget lätt sätt att säga det här
[01:27.64]Men nu är det som det är
[01:29.50]Tyvärr
[01:31.27]Det man vill är inte alltid det man får
[01:35.68]Julen, den är inställd i år
[01:39.58]Sören blev förvånad och sade
[01:42.51]Nej du, så kan vi inte ha det
[01:45.87]Mannen i rött suckade och satte sig ner
[01:48.60]Jo. Jag är Tomten och jag... Jag orkar inte mer.
[01:54.60]Jag förstår att det är mycket för dig nu under julen
[01:58.00]Följ med mig till stugan och vila, så kan vi prata ut sen
[02:03.81]När Sören vaknade morgonen därpå
[02:05.41]Stod Tomten i dörren, på väg att gå
[02:08.53]Vänta, sa Sören, hur blir det med julen?
[02:12.94]Julen? Är den verkligen kul än?
[02:16.99]Är det värt att jaga gran och bara hitta en ful en?
[02:20.55]Ja, sa Sören, vintern är kall och mörk och allmänt trist
[02:25.72]Men tack vare julen finns det ändå en positiv twist
[02:29.52]Jag förstår att Tomtens arbete är mycket hårt
[02:33.91]Men du gör så mycket för de som har det svårt
[02:36.50]Som Emelie, som ständigt slåss mot skurkar
[02:40.39]Hon blir så glad när hon till jul får nya färgburkar
[02:45.90]Eller Anneli, som bor i helvetet på jorden
[02:48.71]Hennes enda glädje är när julklapparna ligger utspridda på borden
[02:53.55]Eller varför inte Nellie, som är livrädd för fåglar
[02:57.96]Men hon älskar att i julklapp få nya skålar
[03:01.62]Okej okej, din poäng har gått fram
[03:05.73]Men "Emelie, Anneli, Nellie"? Måste alla tjejer ha samma namn?
[03:11.91]Tomten skrattade och sade hejdå
[03:13.56]Nu måste jag gå
[03:14.72]Men du kan lita på
[03:16.65]Att nog ska det gå
[03:17.62]Att fira jul ändå
[03:19.01]Och julklappar få
[03:20.64]Till alla små
[03:22.45]Och stora likaså
[03:24.57]Hejdå!
[03:27.74]Så blev julen av ändå, tack vare Sören
[03:30.76]Och än idag, har vi honom att tacka för den
[03:33.55]Det vi vill är kanske inte alltid det vi får
[03:36.61]Men julen, den blir nog av även i år
[03:40.41]Och på julen ska man rimma, även om man inte vill
[03:44.09]Så här kommer några rim till
[03:47.88]Velociped
[03:49.00]Jättesned
[03:51.61]Karriär
[03:53.55]Spektakulär
[03:56.52]Integritet
[03:58.76]Ganska fet
[04:01.71]Ful, Gul, Kul, Strul, Vestibul
[04:04.37]Och så önskar jag dig en riktigt God Jul!
`,
  };

export default data;
